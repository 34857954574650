<template>
  <div>
    <div v-for="(item, i) in cc.buttons" :key="`item-btn-${i}`">
      <ui-btn :data="item" class="btn-w-200 mb-3" type="1" color="orange" />
    </div>
    <div v-for="(item, i) in cc.links" :key="`item-link-${i}`">
      <ui-link :data="item" class="ts-20" color="green" />
    </div>
  </div>
</template>

<script>
import uiBtn from '@/components/UI/Btn'
import uiLink from '@/components/UI/Link'

export default {
  components: {
    uiBtn, uiLink
  },
  computed: {
    cc () {
      return this.$t('demo')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
